/* Reset and Global Styles */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #567fa9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar Styles */
.navbar {
  background-color: #567fa9;
}

.nav-link {
  color: white !important;
}

.nav-logo {
  max-width: 70px;
  max-height: 70px;
}

/* Title Section Styles */
#title {
  text-align: center;
  padding: 50px 0;
}

.title-image {
  width: 50%;
}

.main-title {
  font-family: 'Sacramento', cursive;
  font-size: 3rem;
}

.download-button {
  margin-top: 20px;
}

/* Features Section Styles */
#features {
  text-align: center;
  padding: 50px 0;
}

.feature-box {
  margin: 20px 0;
}

.feature-title {
  font-family: 'Merriweather', serif;
  margin: 10px 0;
}

/* Call to Action Section Styles */
#cta {
  text-align: center;
  padding: 50px 0;
}

.cta-text {
  color: #567fa9;
  font-weight: bold;
}

/* Footer Styles */
#footer {
  text-align: center;
  padding: 20px 0;
  background-color: #567fa9;
  color: white;
}

.social-brand {
  font-size: 30px;
  margin: 0 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main-title {
    font-size: 2rem;
  }

  .title-image {
    display: none;
  }

  /* Add more specific media queries and adjustments as needed */
}

/* cleanform Section Styles */
#main {
  display: flex;
  flex-direction: row;
}

.formDiv {
  background-color: #607a94;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 2.25rem;
}

.formHead {
  font-family: "Montserrat";
  font-weight: bolder;
  line-height: 1.5;
  font-size: 3.5rem;
  text-align: center;
}

.formTitle {
  color: lightgray;
}

.listDiv{
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  display:flex;
  flex-direction: column;
  gap: 1rem;
  border-style: outset;
  background-color: #485563;
  border-radius: 15px;
  text-align: center;
}


/* ... (Continue with other section-specific styles) */
tr{
  border-width:3px;
  border-color: #93bbe4;
  border-style: solid;
}

table{
  width: 100%;
  border-style: ridge;
  border-width: 4px;
  margin-bottom: 10px;
}

.header-logo{
  width:10%
}

.title-image{
  position: static;
  transform: rotate(0);
  width: 80%;
}

.colored-bg{
  background-color: #607A94;
  color:#fff;
}

.colored-bga{
  background-color: #93bbe4;
  color:#fff;
}

.colored-bgb{
  background-color: #27313c;
  color:#fff;
}
.white-bg{
    background-color: #fff;
}

.formItem{
  width: 25%;
  text-align: left;
}

.listHead{
  margin-top:10px;
}

@media (max-width: 750px) {

  /* Adjustments for smaller screens */

  #main{
    display:flex;
    gap:px;
    flex-direction: column;
  }

  .header-logo {
      width: 20%; /* Adjust the width of the header logo */
  }

  .listDiv {
      margin-top: 1rem;
      margin-bottom: 1rem;
  }

  /* Add more adjustments as needed */
}



