/* App-wide styles */
body {
    font-family: 'Oleo Script', script;
    background-color: #567fa9;
  }

  .dashboard{
    display: block;
  }

  .dashboard .menu{
    width:15%;
    margin-top: 10;
    margin-right: 5;
  }

  .dashboard .main-container{
    width:100%;
    display:flex;
    margin: 0;
  }

  .dashboard .listDiv{
    margin-top: 10;
    margin-right: 5;
  }

  .dashboard .dataDiv{
    width: 100%;
  }

  .truckContent table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .truckContent thead th,
  .truckContent tbody td {
    text-align: center;
    padding: 8px; /* Adjust padding as needed */
    width: 25%
  }
  
  .truckContent tbody td {
    border-top: 1px solid #dddddd; /* Add top border to separate rows */
  }
  
  /* Optional: Add hover effect */
  .truckContent tbody tr:hover {
    background-color: #f2f2f2;
    color:black;
  }
  
  .colorBGA{
    background-color: #567fa9;
  }
  
  /* Navbar styles */
  .navbar {
    background-color: #567fa9;
  }
  
  .nav-link {
    color: white !important;
  }
  
  .nav-logo {
    max-width: 110px;
    max-height: 110px;
    margin-left: 20px;
  }
  
  /* Title Section styles */
  #title {
    text-align: center;
    padding: 50px 0;
  }
  
  .main-title {
    font-family: 'Kaushan Script', script;
    font-size:4rem;
  }
  
  .download-button {
    margin-top: 20px;
  }
  
  /* Features Section styles */
  #features {
    text-align: center;
    padding: 50px 0;
  }
  
  .feature-box {
    margin: 20px 0;
  }
  
  .feature-title {
    font-family: 'Merriweather', serif;
    margin: 10px 0;
  }
  
  /* Call to Action Section styles */
  #cta {
    text-align: center;
    padding: 50px 0;
  }
  
  .cta-text {
    color: #567fa9;
    font-weight: bold;
  }
  
  /* Footer styles */
  #footer {
    text-align: center;
    padding: 20px 0;
    background-color: #567fa9;
    color: white;
  }
  
  .social-brand {
    font-size: 30px;
    margin: 0 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .main-title {
      font-size: 2rem;
    }
    
    .title-image {
      display: none;
    }
  }
  
  /* Custom styles */
  /* Add any custom styles here */
  
  .slotsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .slot {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: calc(100% - 20px); /* Adjust the width for smaller screens */
    text-align: center;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .registerButton {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Media query for devices with width up to 768px */
  @media (max-width: 768px) {
    .slotsContainer {
      gap: 10px;
    }
  
    .slot {
      width: calc(50% - 20px); /* Adjust the width to take half of the container's width, minus gap */
    }
  
    .registerButton {
      font-size: 14px; /* Slightly reduce font size for smaller screens */
      padding: 8px 16px;
    }
  }
  
  /* Media query for devices with width up to 480px */
  @media (max-width: 480px) {
    .slot {
      width: 100%; /* Make slot elements take full width of the container on very small screens */
    }
  }
  

  .table-container {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Enable vertical scrollbar if content exceeds container height */
    text-align: center;
  }

  .performance-table{
    max-height: 600px;
  }
  
  /* Optional: Style for the search bar */
  .search-bar {
    margin-bottom: 10px;
  }
  
  .search-bar input {
    width: 20%;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .scrollable-tbody {
    max-height: 300px; /* Adjust this value based on your needs */
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .table-container table {
    width: 100%;
    border-collapse: collapse;
    text-align: center
    ;
  }
  
  .table-container th, .table-container td {
    padding: 8px; /* Adjust padding as needed */
    text-align: center;
    border-bottom: 1px solid #ddd; /* Adjust styling as needed */
  }
  
  .table-container thead {
    width: calc(100% - 1em); /* Adjust based on scrollbar width to avoid column misalignment */
    table-layout: fixed; /* This helps keep columns aligned */
  }
  
  .table-container thead th {
    position: sticky;
    top: 0;
    background-color: #567fa9; /* Adjust background color as needed */
    z-index: 1; /* Ensures the header is above other content */
  }

  /* TruckForm.css */

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  margin-bottom: 10px;
}

.input-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* admin sidebar menu */

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0; /* Ensure no padding is causing gaps */
  align-items: center;
}

.menu-item {
  border: 2px solid #000; /* Frame appearance */
  padding: 10px;
  cursor: pointer;
  box-shadow: 3px 3px #888; /* Gives a raised effect */
  transition: all 0.3s ease; /* Smooth transition for the inset effect */
  margin-top: 10px;
}

.menu-item:hover {
  background-color: #567fa9; /* Slight hover effect, optional */
}

.selected {
  box-shadow: inset 3px 3px 5px #000; /* Inset appearance when selected */
  background-color: #567fa9; /* Slightly darker background for selected items */
}

.checkbox-header, .checkbox-cell {
  width: 50px;  /* Adjust width as necessary */
  padding: 0;
  text-align: center;
}

.checkbox-cell input {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.search-and-add-bar {
  display: flex;
  align-items: center; /* Aligns items vertically in the middle */
  justify-content: space-between; /* Pushes select to the left and h2 to the center */
  padding: 0 20px; /* Adds padding on both sides of the container */
}

.search-and-add-bar div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-label {
  margin-bottom: 5px;
  font-weight: bold;
}


.details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Darker background for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.details-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 90%;
  max-width: 800px;
  position: relative; /* Required for absolute positioning of children */
  color: black; /* Ensuring text is visible */
}

/* Close button in the top-right corner */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  color: #333;
  font-size: 24px;
  cursor: pointer;
}

.details-row {
  padding: 5px 0; /* Add some vertical padding for each detail item */
}


.scrollable-textbox {
  width: 100%;
  height: 75px;
  margin-top: 10px;
  overflow-y: auto;
  background-color: #f8f8f8; /* Light grey background for the textarea */
  border: 1px solid #ccc; /* Subtle border for the textarea */
  padding: 8px; /* Padding inside the textarea */
  box-sizing: border-box; /* Border and padding included in width/height */
}

/* Modal backdrop styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content styling */
.modal-content {
  background: #567fa9;
  color:#000;
  font-size: 16px; /* Larger text for better readability */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 80%;
  max-height: 90vh;
  position:relative;
  z-index: 2; /* Above the backdrop */
  overflow-y:auto;
}

.modal-group{
  display: flex;
}

.detail-data{
  text-align: left;
  width:50%;
}



/* Modal close button styling */
.modal-close {
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 24px;
  color: #333;
  cursor:pointer;
}

/* Example styles for enhancing visibility of images if included */
.image-container {
  margin-top: 10px;
  border-top: 1px solid #ccc; /* Separation from text content */
  padding-top: 10px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.carousel-slides {
  display: flex;
  overflow: hidden;
  width: 90%; /* Adjust based on modal size */
  justify-content: space-between;
}

/* Navigation buttons */
.carousel button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
}

.carousel-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px; 
}

.image-wrapper {
  width:100%;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
}

.image-wrapper.active {
  visibility: visible; /* Only the active image is visible */
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease;
  justify-content: center;
  min-width: 100%; 
}

.image-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index: 11;
}

.carousel-image {
  width: 80;
  object-fit: cover;
  max-height:325;
}

.carousel-slide img.active {
  opacity: 1;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  z-index: 10; /* Higher z-index */
}

.carousel-control.left {
  left: 10px;
}

.carousel-control.right {
  right: 10px;
}

.heading-div{
  width: 33.3333%;
}

.timeframe-dropdown {
  margin-right: auto; /* Ensures that select stays on the left but away from the edge */
  margin-left: 20px; /* Adds margin to the left of select */
}

.content-title {
  text-align: center; /* Centers the title text horizontally */
  flex-grow: 1; /* Allows the title to take up any remaining space */
  z-index: 2;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  color: #fff;
}

.image-slideshow-container {
  position: relative;
  text-align: center;
  padding: 20px;
}

.displayed-image {
  width: 100%; /* Adjust width as necessary */
  max-width: 600px; /* Set max width for larger screens */
  height: auto;
  margin: auto; /* Center the image */
}

.image-index {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.save-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.save-button:hover {
  background-color: #0056b3;
}


.input-style {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.select-style {
  appearance: none; /* Remove default style */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  background-color: white;
  position: relative; /* Needed for positioning the arrow */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: right 10px center; /* Position the background image */
  background-size: 5px 5px; /* Size of the arrow */
  background-repeat: no-repeat; /* Make sure the arrow doesn't repeat */
}

.details-row label {
  display: flex;
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  text-align: center;
  color: #007bff;
  cursor: pointer;
}

.nav-link-dropdown{
  margin-right: 20px;
  width:max-content ;
}

.navdiv{
  width:20%;
}

.navdivcenter{
  width:60%;
  text-align: center;
}

/* Make the table scrollable on larger screens */
@media (min-width: 600px) {
  .scrollable-table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* On smaller screens, the table will take full width and not be scrollable */
@media (max-width: 599px) {
  .scrollable-table {
    width: 100%;
    display: block;
    overflow-x: visible;
    white-space: normal;
  }
}
